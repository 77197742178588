.frase-drop {
  font-family: unica;
  display: flex;
  justify-content: center;
  font-size: 10vh;
  margin-bottom: 1%;
  margin-top: 1%;
  padding-left: 1vh;
  padding-right: 1vh;
  background: linear-gradient(90deg, #e4ff00 0%, #e4ff00 100%);
}
.titulo-de-slogan-drop {
  font-family: unica;
  font-size: 6vh;
  text-align: center;
  align-self: center;
  margin-top: 20px;
  margin-bottom: 20px;
  color: black;
}
@media screen and (max-width: 992px) {
  .frase-drop {
    margin-top: 0.1vh;
    display: flex;
    justify-content: center;
    font-size: 8vh;
    background: linear-gradient(90deg, #e4ff00 0%, #e4ff00 100%);
    margin-bottom: 0.1vh;
  }
  .titulo-de-slogan-drop {
    font-family: unica;
    font-size: 32px;
    margin-bottom: 3%;
    margin-top: 3%;
    margin-left: 5vh;
    margin-right: 5vh;
    text-align: center;
  }
}

@font-face {
  font-family: unica;
  src: url(../../public/UnicaOne-Regular.ttf);
}
