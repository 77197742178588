.tracking-container {
  display: flexbox;
  justify-content: center;
  padding-top: 4%;
  width: 98%;
}
.center-this-bs {
  display: flex;
  justify-content: center;
}
.tracking-title {
  font-family: unica;
  display: flex;
  justify-content: center;
  font-size: 400%;
  margin-top: 5vh;
  margin-bottom: 2%;
}
.form-tracking {
  display: flex;
  justify-content: center;
  width: 70%;
  transition: 2s;
}
.center-input {
  display: flex;
  justify-content: center;
}

.texto-a-insertar {
  display: grid;
  margin-top: 4%;
  font-size: 1.3rem;
  text-align: center;
  padding-left: 3.5%;
  padding-right: 3.5%;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.spinner-container {
  display: flex;
  justify-content: center;
  padding-top: 40vh;
  height: 90vh;
}

.loading-spinner {
  text-align: center;
  width: 30px;
  height: 30px;
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.button-div-tracking {
  display: flex;
  justify-content: center;
  padding-top: 2%;
  font-size: 16px;
  width: 100%;
}
.extra-button-tracking {
  width: 20%;
}
@font-face {
  font-family: rubik;
  src: url(../../public/Rubik-VariableFont_wght.ttf);
}

@font-face {
  font-family: unica;
  src: url(../../public/UnicaOne-Regular.ttf);
}

/* CSS */
.button-3 {
  appearance: none;
  background-color: #2ea44f;
  border: 1px solid rgba(100, 145, 190, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.1) 0 1px 0;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 40%;
  padding: 1% 1%;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  height: 5vh;

  /*
      not selectable
      */
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.button-3::selection {
  background: transparent;
}

.button-3:focus:not(:focus-visible):not(.focus-visible) {
  box-shadow: none;
  outline: none;
}

.button-3:hover {
  background-color: #2c974b;
}

.button-3:focus {
  box-shadow: rgba(46, 164, 79, 0.4) 0 0 0 3px;
  outline: none;
}

.button-3:disabled {
  background-color: #94d3a2;
  border-color: rgba(27, 31, 35, 0.1);
  color: rgba(255, 255, 255, 0.8);
  cursor: default;
}

.button-3:active {
  background-color: #298e46;
  box-shadow: rgba(20, 70, 32, 0.2) 0 1px 0 inset;
}

.answer {
  text-align: center;
  font-size: 300%;
  display: none;
}

.answer-showed {
  display: block;
  text-align: center;
  font-size: 30px;
  font-weight: 200;
  padding: 5vw;
  font-family: rubik;
  margin-top: 3vh;
}

.frase-explicacion-tracking {
  font-weight: 200;
  margin-top: 3%;
  font-family: rubik;
  display: grid;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  text-align: center;
}

.estado-respuesta {
  margin-top: 2vh;
  font-weight: bolder;
  color: #298e46;
}
.team-name-ans {
  color: #298e46;
  font-weight: 400;
  font-size: 42px;
  margin-top: 3%;
}

.div-proceso-prod {
  display: flex;
  justify-content: center;
}

.imagen-proceso-productivo {
  max-width: 90vw;
}

.not-a-code-text {
  font-size: 32px;
  font-weight: 200;
}

@media only screen and (max-width: 700px) {
  .tracking-container-container {
    display: flex;
    justify-content: center;
    max-width: 100vw;
    margin: 0;
  }
  .frase-explicacion-tracking {
    font-size: 16px;
    text-align: center;
    margin-bottom: 3%;
    padding: 0px;
    margin-top: 10%;
    margin: 0px;
    
  }
  .button-3 {
    width: 90vw;
  }
  .tracking-container {
    max-width: 100vw;
    padding: 0;
    margin: 0;
    padding-top: 10%;
  }
  .answer-showed {
    font-size: 130%;
  }

  .form-tracking {
    display: flex;
    justify-content: center;
    max-width: fit-content;
    transition: 2s;
    text-align: center;
  }
  .form-tracking-tracking {
    max-width: 100vw;
    display: flexbox;
    place-items: center;
    text-align: center;
    justify-content: center;
  }
  .button-div-tracking {
    display: grid;
    text-align: center;
    justify-content: center;
    align-self: center;
    place-items: center;
  }
  .button-3 {
    max-width: 90vw;
  }
  .texto-a-insertar {
    display: inline;
    align-self: center;
    font-size: 6.6vw;
    padding: 0;
  }
}
