.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100vh;
  width: 100vw;
  max-height: 100vh;
  place-content: center;
}

.change-image-container {
  position: relative;
  top: 0;
  left: 0;
}
.change-image-container-left {
  position: relative;
  top: 0;
  left: 0;
}
.left-image-drop {
  display: grid;
  position: relative;
  justify-content: center;
  overflow: hidden;
  width: 50vw;
  height: 100vh;
  transition: 3s;
}

.front-image-right {
  position: absolute;
  top: 0vh;
  left: 0px;
  opacity: 1;
  transition: 3s;
  z-index: 4;
  width: 50vw;
  height: 100vh;
}

.change-image-container:hover .back-image-right {
  opacity: 1;
}



.left-image-drop:hover {
  transform: scale(1.1);
}
.front-image-left {
  position: absolute;
  top: 0vh;
  left: 0px;
  opacity: 1;
  transition: 3s;
  z-index: 4;
  width: 50vw;
  height: 100vh;
}

.midle-image-left {
  opacity: 1;
  transition: 3s;
  position: relative;
  width: 50vw;
  height: 100vh;
  z-index: 0;
}

.midle-image-right {
  opacity: 1;
  transition: 3s;
  position: relative;
  width: 50vw;
  height: 100vh;
  z-index: 0;
}

.left-image-drop {
  float: left;

  /*Animations*/
  -webkit-transition: all 3s ease;
  -moz-transition: all 3s ease;
  -o-transition: all 3s ease;
  transition: all 3s ease;
}

.left-image-drop.fullscreen {
  z-index: 9999;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.back-image-right {
  position: absolute;
  top: 0vh;
  left: 0px;
  opacity: 0;
  transition: 3s;
  z-index: 3;
  width: 50vw;
  height: 100vh;
}

.back-image-left {
  position: absolute;
  top: 0vh;
  left: 0px;
  opacity: 0;
  transition: 3s;
  z-index: 3;
  width: 50vw;
  height: 100vh;
}



.right-image-bloob {
  display: grid;
  justify-content: center;
  overflow: hidden;

  transition: 3s;
}

.right-image-bloob:hover {
  transform: scale(1.1);
}

.left-text {
  font-family: unica;
  color: rgb(0, 0, 0);
  font-size: 200%;
  position: absolute;
  bottom: 15vh;
  cursor: pointer;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  transition: 1s ease;
  width: 100%;
  opacity: 0;
  font-weight: 800;
  overflow: hidden;
  z-index: 99;
}

.right-text {
  font-family: unica;
  color: white;
  font-size: 200%;
  position: absolute;
  bottom: 15vh;
  left: 50%;
  cursor: pointer;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  transition: 1s ease;
  width: 100%;
  opacity: 0;
  font-weight: 800;
  overflow: hidden;
  z-index: 99;
}

.left-image-drop:hover .left-text {
  opacity: 1;
  font-size: 250%;
}

.right-image-bloob:hover .right-text {
  opacity: 1;
  font-size: 250%;
}
.change-image-container-left:hover .back-image-left {
  opacity: 1;
}

@media screen and (max-width: 1024px) {
  .container {
    grid-template-columns: 1fr;
    height: 100vh;
    max-height: 100vh;
    width: 100vw;
    place-items: center;
  }
  .change-image-container {
    height: 50vh;
    width: 100wh;
  }
  .change-image-container-left {
    height: 50vh;
    width: 100wh;
  }

  .right-image-bloob {
    margin: 0 auto;
    height: 50vh;
    width: 100wh;
    place-items: center;
  }
  .left-image-drop {
    display: grid;
    margin: auto;
    place-items: center;
    height: 50vh;
    width: 100vw;
  }
  .front-image-left{
  height: 50vh;
    width: 100vw;
  }
  .back-image-left{
    height: 50vh;
    width: 100vw;
    position: relative;
  }
  .midle-image-left{
        height: 50vh;
    width: 100vw;
    position: relative;
  }
    .front-image-right{
  height: 50vh;
    width: 100vw;
  }
  .back-image-right{
    height: 50vh;
    width: 100vw;
    position: relative;
  }
  .midle-image-right{
        height: 50vh;
    width: 100vw;
    position: relative;
  }
  .right-text{
    z-index: -4;
    opacity: 0%;
  }
  .left-text{
    opacity: 0%;
    z-index: -4;
  }
}

@font-face {
  font-family: rubik;
  src: url(../../public/Rubik-VariableFont_wght.ttf);
}

@font-face {
  font-family: unica;
  src: url(../../public/UnicaOne-Regular.ttf);
}
