.containerReview {
  display: flexbox;
  justify-content: center;
  border-style: none;
  border-width: 2px;
  background-color: rgb(0, 0, 0);
  margin-bottom: -5vh;
  transition: 2s;
  font-family: rubik;
  min-width: 50%;
  max-width: 50%;
}

.titleReview {
  font-size: 5vh;
  text-decoration: solid;
  font-weight: bold;
  padding-bottom: 3vh;
  display: flex;
  padding-top: 3vh;
  justify-content: center;
  font-family: rubik;
}
p {
  width: 100%;
  padding: 2vh;
  font-size: 40%;
}
.descripcionP {
  width: 100%;
  padding: 2vh;
  font-size: 150%;
}
.descriptionReview {
  min-height: 35%;
  font-family: rubik;
  display: flex;
  justify-content: center;
  text-align: center;
  padding-left: 5vh;
  padding-right: 5vh;
}

.containerImagenProfile {
  display: flex;
  justify-content: center;
  padding-top: 3vh;
  align-items: flex-end;
}

.imagenProfileAbout {
  display: flexbox;
  justify-content: center;
  align-self: flex-end;
}
.imagenProfile {
  height: 10vh;
  width: 10vh;
}

.left-button-review {
  color: rgb(255, 255, 255);
  height: 6vh;
  width: 7vh;
  font-size: 5vh;
  display: flexbox;
  align-self: center;
  border: none;
  background-color: black;
  overflow: hidden;
}

.right-button-review {
  color: rgb(255, 255, 255);
  height: 6vh;
  width: 7vh;
  font-size: 5vh;
  display: flexbox;
  align-self: center;
  border: none;
  background-color: black;
  overflow: hidden;
}

.estrellitas {
  padding-top: 0px;
  padding-bottom: 2vh;
}

.nombresito {
  padding-top: 0px;
  padding-bottom: 2vh;
  font-size: 150%;
}

@font-face {
  font-family: rubik;
  src: url(../../public/Rubik-VariableFont_wght.ttf);
}

@media only screen and (max-width: 1024px) {
  .containerReview {
    display: grid;
    
  }
  .descriptionReview {
    padding: 5vw;
    margin: 0;
    padding-top: 0%;
  }
  .descripcionP {
    max-width: 100%;
    font-size: 16px;
  }
  .titleReview {
    font-size: 20px;
    max-width: 99vw;
  }
  .containerReview {
    max-width: 99vw;
    min-height: 50vh;
  }
  .containerImagenProfile {
    max-width: 99vw;
  }
}
