.container-footer{
  margin-top: auto;
  min-height: 15vh;
}

.container-footer-drop{
  margin-top: auto;
  min-height: 15vh;
}

.footer-information {
  display: flex;
  margin-bottom: 0%;
  padding-bottom: 0%;
  padding-left: 3%;
  margin-top: 5%;
}

h2 {
  font-family: rubik;
}
.footer-info-text {
  font-size: 1.2vw;
  font-weight: 300;

}
.container-footer {
  width: 100%;
}

.bloob-logo-abajo {
  height: 100%;
}

.logo-de-bloob-izquierda {
  max-width: 7%;
  padding-right: 0px;
}

.footer-information {
  font-family: rubik;
  padding-top: 1%;
}

.footer-marketing {
  border-style: solid;
  border-radius: 33px;
  border-color: white;
  padding: 1%;
}

.texto-footer-information {
  font-family: unica;
  padding-left: 2%;
  border: none;
  line-height: 25px;
}

.redes-sociales-footer {
  display: flex;
  justify-content: right;
  align-self: left;
  padding-top: 5%;
  background-color: rgb(0, 0, 0);
  transition: 1s;
}


.ig-y-email {
  display: flex;
  justify-content: space-between;
}

.logito-ig{
  font-size:1.1vw;
}

@font-face {
  font-family: rubik;
  src: url(../../public/Rubik-VariableFont_wght.ttf);
}

@font-face {
  font-family: unica;
  src: url(../../public/UnicaOne-Regular.ttf);
}


@media only screen and (max-width: 1024px) {
  .footer-information {
    display: grid;
    text-align: center;
    width: fit-content;
    font-size: 5vw;
    padding-left: 0px;
  }
  .row{
    margin: 0px;
  }
  .column{
    padding: 0px;
  }
  .bloob-logo-abajo {
    max-width: 30vw;
  }
  .ig-y-email{
    justify-content: center;
    gap: 2vw;
}
.logito-ig{
  font-size:3.5vw;
}
.logo-de-bloob-izquierda {
    max-width: 100%;
    padding-left: 0px;
    
  }
  .texto-footer-information{
    max-width: 100vw;
    padding-bottom: 5%;
    padding-right: 10%;
    padding-left: 10%;
  }
  .footer-info-text {
    font-size: 80%;
    font-weight: 300;

  }
  .footer-marketing{
    padding-top: 5%;
  }
}