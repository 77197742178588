.pdf-container{
    display: flex;
    justify-content: center;
    margin-top: 5%;
    margin-bottom: 3%;
}

#myPDF {
    width: 50vw;
    height: 90vh;
}