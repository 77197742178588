* {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
}

body {
  color: rgb(255, 255, 255);
  background-color: rgb(0, 0, 0);
  font-family: unbounded;

  width: 100%;
  height: 100%;
}
@font-face {
  font-family: antonio;
  src: url(../../public/Antonio-VariableFont_wght.ttf);
}

@font-face {
  font-family: unbounded;
  src: url(../../public/Unbounded-VariableFont_wght.ttf);
}



.img-logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
}

.imagen-agrandar-drop{
  width: 65px;
  height: 65px;
}

.navigation-bar {
  z-index: 999;
  display: flex;
  position: fixed;
  width: 100%;
  justify-content: space-between;
  overflow: hidden;
  font-family: antonio;
  max-width: 100vw;
  
}


/* Style the links inside the navigation bar */
.navigation-bar a {
  flex: wrap;
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 1%;
  padding-bottom: 1%;
  float: left;
  text-align: center;
  text-decoration: none;
  font-size: 1.8vw;
  font-weight: 300;
  color: rgb(0, 0, 0);
}



/* Change the color of links on hover */
.menu-drop a:hover {
  background-color: rgb(0,0,0);
  color: rgb(228, 255, 0);
}

/* Add a color to the active/current link */

.img-logo a:hover {
  background-color: rgb(255, 0, 0,0);
  color: rgb(142, 1, 1,0);
}



.menu-drop {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}

header .nav-btn-drop {
  visibility: hidden;
  opacity: 0;
}

@media only screen and (max-width: 1024px){
  header .nav-btn-drop {
    visibility: visible;
    opacity: 1;
    color: rgb(0, 0, 0);
    background-color: rgb(228, 255, 0);
    border: none;
    padding: 10;
    padding-right: 7%;
    padding-top: 5%;
    margin-left: 10px;
    font-size: x-large;
  }
  .navigation-bar a{
    font-size: 5vw;
  } 
  header nav{
    position: fixed;
    top:0;
    left:0;
    height: 100%;
    width: 100%;
    max-width: 100vw;
    max-height: 100vh;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: rgb(228, 255, 0);;
    transition: 0.5s;
    color: black;
    transform: translateY(-100vh);


  }

  header .responsive_nav-drop{
    transform: none;

  }

  nav .nav-close-btn {
    position: absolute;
    top: 1.5rem;
    right: 0.1rem;
  }


}

@media only screen and (max-width: 500px){
  header .nav-btn-drop {
    visibility: visible;
    opacity: 1;
    color: rgb(0, 0, 0);
    background-color: rgb(228, 255, 0);;
    border: none;
    padding: 10;
    padding-right: 10%;
    padding-top: 5%;
    margin-left: 10px;
  }

}


