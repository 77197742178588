.master-container {
  display: flexbox;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  text-align: center;

}

.actualizar-container {
  display: flexbox;
  justify-content: center;
  text-align: center;
  align-items: center;

  margin: 1%;
}

.titulo-actualizar {
  display: flexbox;
  justify-content: center;
  font-size: 40px;
}


.nuevo-cliente-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.titulo-cliente-nuevo {
  display: flexbox;
  justify-content: center;
  font-size: 40px;
}
.todos-los-clientes-container {
  display: flexbox;
  justify-content: center;
  margin: 3%;
}

.titulo-todos-los-clientes {
  font-size: 40px;
}

.list-container{
  display: flex;
  min-height: 10vh;
  justify-content: center;
  text-align: center;
  border: solid;
  flex-direction: column;
  margin-bottom: 2%;
}


.stage{
  margin-bottom: 1%;
}
.team-in-the-list{
  border: 3px;
  border-style: solid;
  margin: 4px;
  padding: 5px;
}

.login-form{
  margin-top: 3rem;
  display: flexbox;
  width: 100%;
  text-align: center;
  justify-content: center;
}
.texto-a-insertar-login{
  margin-top: 2rem;
  font-size: 1rem;
}

.titulo-master-data{
  font-size: 3rem;
}
