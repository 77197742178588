.container{
    display: flexbox;
    justify-content: center;
}

  .container-productos{
    display: flex;
    justify-content: center;
    margin-bottom: 3%;
  }
  .row{
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: top;
    width: 90%;
    gap: 10vh;
    margin: 0;
  }
  .row:after {
    content: "";
    display: table;
    clear: both;
  }

  @media only screen and (max-width: 720px) {
  
    .row{
      display: grid;
      justify-content: center;
      align-items: top;
      width: 90%;
      gap: 10vh;
      margin: 3vh;
    }
    .container-productos{
      margin-bottom: 10vh;
    }
  }