.talles-container{
    padding-top: 4%;
}

.titulo-carrito{
    font-family: unica;
    display: flex;
    justify-content: center;
    font-size: 400%;
    margin-bottom: 3%;
    margin-top: 5vh;
}