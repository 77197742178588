.parent-div {
  display: flex;
  justify-content: center;
  width: 100%;
}

.container-prod-ind {
  display: flex;
  justify-content: center;
  align-items: stretch;
  background-color: black;
  margin-bottom: 20px; /* espacio entre los productos*/
  overflow: hidden;
  transition: 2s;
  width: 100%;
  height: 50vh;
}
.imagen {
  width: 100%;
  height: fit-content;
  filter: brightness(50%);
  transition: 1.5s;
  position: absolute;
  top: -9850px;
  bottom: -9999px;
  left: -9999px;
  right: -9999px;
  margin: auto;
  transition: 1.5;
}

.imagen1 {
  width: 100%;
  height: fit-content;
  filter: brightness(50%);
  transition: 1.5s;
  position: absolute;
  top: -8890px;
  bottom: -9999px;
  left: -9999px;
  right: -9999px;
  margin: auto;
}

.imagen3 {
  width: 100%;
  height: fit-content;
  filter: brightness(50%);
  transition: 1.5s;
  position: absolute;
  top: -9500px;
  bottom: -9999px;
  left: -9999px;
  right: -9999px;
  margin: auto;
}

.imagen4 {
  width: 100%;
  height: fit-content;
  filter: brightness(50%);
  transition: 1.5s;
  position: absolute;
  top: -9200px;
  bottom: -9999px;
  left: -9999px;
  right: -9999px;
  margin: auto;
}

.imagen5 {
  width: 100%;
  height: fit-content;
  filter: brightness(50%);
  transition: 1.5s;
  position: absolute;
  top: -9700px;
  bottom: -9999px;
  left: -9999px;
  right: -9999px;
  margin: auto;
}

.imagen6 {
  width: 100%;
  height: fit-content;
  filter: brightness(50%);
  transition: 1.5s;
  position: absolute;
  top: -9999px;
  bottom: -9999px;
  left: -9999px;
  right: -9999px;
  margin: auto;
}
a.productos-link {
  position: relative;
  overflow: hidden;
  display: flex;
  width: 100%;
  color: white;
}
.seccion-individual-titulo {
  width: 90%;
  padding-left: 5%;
  display: flex;
  justify-content: center;
}
h1.seccion-individual-titulo {
  display: flexbox;
  justify-content: center;
}

.imagen:hover {
  filter: brightness(100%);
  scale: 0.95;
}

.imagen1:hover {
  filter: brightness(100%);
  scale: 0.90;
}

.imagen2:hover {
  filter: brightness(100%);
  scale: 0.90;
}

.imagen3:hover {
  filter: brightness(100%);
  scale: 0.90;
}

.imagen4:hover {
  scale: 0.90;
  filter: brightness(100%);
}
.imagen5:hover {
  scale: 0.90;
  filter: brightness(100%);
}
.imagen6:hover {
  scale: 0.90;
  filter: brightness(100%);
}

.seccion-individual-titulo {
  z-index: 2;
  font-family: unica;
  position: absolute;
  justify-content: center;
  align-self: center;
  font-size: 5vh;
  filter: brightness(100%);
  transition: 1s;
}

@font-face {
  font-family: unica;
  src: url(../../public/UnicaOne-Regular.ttf);
}

.seccion-individual-titulo:hover {
  filter: brightness(100%);
  transform: scale(1.2);
  overflow: hidden;
}

@media screen and (max-width: 992px) {
  .seccion-individual-titulo {
    font-family: unica;

    width: 90%;
    padding-left: 5%;
    font-size: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .imagen {
    width: 100%;
    height: fit-content;
    filter: brightness(70%);
    transition: 1.5s;
    position: absolute;
    top: -9850px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
    scale: 120%;
  }

  .imagen1 {
    width: 100%;
    height: fit-content;
    filter: brightness(70%);
    transition: 1.5s;
    position: absolute;
    top: -9290px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    scale: 120%;
    margin: auto;
  }

  .imagen3 {
    width: 100%;
    height: fit-content;
    filter: brightness(70%);
    transition: 1.5s;
    position: absolute;
    top: -9500px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
    scale: 120%;
  }

  .imagen4 {
    width: 100%;
    height: fit-content;
    filter: brightness(70%);
    transition: 1.5s;
    position: absolute;
    top: -9200px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
  }

  .imagen5 {
    width: 100%;
    height: fit-content;
    filter: brightness(70%);
    transition: 1.5s;
    position: absolute;
    top: -9700px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
  }

  .imagen6 {
    width: 100%;
    height: fit-content;
    filter: brightness(70%);
    transition: 1.5s;
    position: absolute;
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
  }
}

.container-prod-ind {
  max-height: 50%;
}

.seccion-individual-titulo {
  max-height: 50%;
}

@media screen and (max-width: 700px) {
  .imagen1 {
    width: 100%;
    height: fit-content;
    filter: brightness(90%);
    transition: 1.5s;
    position: absolute;
    top: -9790px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
  }
  .imagen {
    width: 100%;
    height: fit-content;
    filter: brightness(90%);
    transition: 1.5s;
    position: absolute;
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
    zoom: 400%;
  }
  .imagen3 {
    width: 100%;
    height: fit-content;
    filter: brightness(90%);
    transition: 1.5s;
    position: absolute;
    top: -9900px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    zoom: 200%;
    margin: auto;
  }
  .imagen4 {
    width: 100%;
    height: fit-content;
    filter: brightness(90%);
    transition: 1.5s;
    position: absolute;
    top: -9790px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
  }

  .imagen5 {
    width: 100%;
    height: fit-content;
    filter: brightness(70%);
    transition: 1.5s;
    position: absolute;
    top: -9993px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
  }

  .imagen6 {
    width: 100%;
    height: fit-content;
    filter: brightness(70%);
    transition: 1.5s;
    position: absolute;
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
  }
  a.productos-link {
    overflow-y: hidden;
  }
  .container-prod-ind {
    height: 30vh;
  }
}
