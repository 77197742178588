.frase {
  font-family: unica;
  display: flex;
  justify-content: center;
  margin-bottom: 0.1%;
  margin-top: 0.1%;
  padding-left: 1vh;
  padding-right: 1vh;
  padding-top: 9px;
  padding-bottom: 9px;
  background: rgb(0, 246, 255);
  align-content: center;
  text-align: center;
  background: linear-gradient(
    90deg,
    rgba(0, 246, 255, 1) 0%,
    rgba(255, 0, 240, 1) 100%
  );
}

.h2-frase{
  text-align: center;
  margin: 0px;
  padding: 0px;
  text-align: center;
}

.titulo-de-slogan {
  font-family: unica;
  font-size: 70px;
  text-align: center;
  align-self: center;
  margin-top: 10px;
  margin-bottom: 20px;
  color: white;
  letter-spacing: .3em;
  border-right: .1em solid rgb(255, 255, 255);
  animation: 
  blink-caret .5s step-end infinite;
}

@media screen and (max-width: 992px) {
  .frase {
    margin-top: 0.1%;
    display: flex;
    justify-content: center;
    font-size: 1vh;
    background: rgb(0, 246, 255);
    background: linear-gradient(
      90deg,
      rgba(0, 246, 255, 1) 0%,
      rgba(255, 0, 240, 1) 100%
    );
  }
  .titulo-de-slogan {
    font-family: unica;
    font-size: 30px;
    margin-left: 5vh;
    margin-right: 5vh;
    text-align: center;
  }
}

@font-face {
  font-family: unica;
  src: url(../../public/UnicaOne-Regular.ttf);
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: #fff;
  }
}

@media screen and (max-width: 400px) {
.h2-frase{
  font-size: 1vh;

}
.typewrite{
  font-size: 20px;
}

}