.editor-name{
    background-color: red;
}

.container-editor{
    padding-top: 20vh;
    display: inline-flex;
    max-width: 80%;
}

canvas {
    width: 59vh;
    height: 50vh;
}

.seleccionar-disenio{
    border: solid;
    margin-left: 5vh;
    margin-right: 5vh;
    width: 50vh;
    min-width: 50vh;
    display: flexbox;
}

.titulo-disenio{
    text-align: center;
    border-bottom: solid;
}