.div-outside-colabs{
    max-width: 50vw;
}
.imgColaboraciones {
    border-radius: 10px;
    border-color: rgb(0, 0, 0);
    background-color: rgb(0, 0, 0);
    max-height: 50vh;
    max-width: fit-content;
    max-width: 50vw;
    max-height: 55vh;
}


.imagen-colaboracion{
    max-width: inherit;
    max-height: inherit;
}
@media only screen and (max-width: 1024px) {
    .div-outside-colabs{
        display: grid;
        width: 100%;
        justify-content: center;
    }
    
    .imgColaboraciones {
        display: grid;
        text-align: center;
        border-color: rgb(0, 0, 0);
        background-color: rgb(0, 0, 0);
        width: 90%;
        margin-top: 10vh;
        max-width: 50vw;
    }
    
    .imagen-colaboracion{
        text-align: center;
        max-height: 50vh;
    }
}