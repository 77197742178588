.container-footer-drop{
  margin-top: auto;
  min-height: 15vh;
}
.footer-information-drop {
  display: flex;
  margin-top: 1%;
  padding-left: 3%;
}

h2 {
  font-family: rubik;
}
.footer-info-text-drop {
  font-size: 1.2vw;
  color: black;
  font-weight: 300;
}
.container-footer-drop {
  padding-bottom: 1%;
  padding-top: 1%;
  background-color: #e4ff00;
  margin-top: 1%;
}

.bloob-logo-abajo-drop {
  max-width: 10vh;
  max-height: 10vh;
  height: 100%;
}

.logo-de-bloob-izquierda-drop {
  max-width: 7%;
  padding-right: 0px;
}

.footer-information-drop {
  font-family: rubik;
}

.footer-marketing-drop {
  border-style: solid;
  border-color: white;
}

.texto-footer-information-drop {
  font-family: unica;
  border: none;
  line-height: 25px;
  padding-left: 30px;
}

.redes-sociales-footer-drop {
  display: flex;
  justify-content: right;
  align-self: left;
  background-color: #e4ff00;
  transition: 1s;
}

.ig-y-email-drop {
  display: flex;
  justify-content: space-between;
}

.logito-ig-drop{
  font-size: 1.1vw;
}

@font-face {
  font-family: rubik;
  src: url(../../public/Rubik-VariableFont_wght.ttf);
}

@font-face {
  font-family: unica;
  src: url(../../public/UnicaOne-Regular.ttf);
}

@media only screen and (max-width: 1024px) {
  .footer-information-drop {
    display: grid;
    text-align: center;
    width: 100vw;
    font-size: 5vw;
    overflow-x: hidden;
    padding: 0%;
  }
  .footer-information-drop {
    background-color: #e4ff00;
  }
  .bloob-logo-abajo-drop {
    max-width: 30vw;
  }
  .ig-y-email-drop {
    justify-content: center;
    gap: 2vw;
  }
  .logo-de-bloob-izquierda-drop {
    max-width: 100%;
    padding-right: 0px;
  }
  .texto-footer-information-drop {
    max-width: 100vw;
    padding-bottom: 5%;
    padding-right: 10%;
    padding-left: 10%;
  }
  .footer-info-text-drop {
    font-size: 80%;
    color: black;
    font-weight: 300;

  }


  .logito-ig-drop{
    font-size: 3.5vw;
  }
}
