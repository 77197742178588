.todo-en-verproducto {
  padding-top: 4%;
}

.division-izquierda-derecha {
  display: flex;
  justify-content: space-evenly;
}
.imagenes-inferiores {
  margin: 0px;
  display: flex;
}

.titulo-camiseta-aca {
  font-family: unica;
  font-size: 2vw;
  margin-bottom: 1%;
}

.imagensuperior {
  max-width: 50vh;
  max-height: 120vh;
}
.imagen-inferior-izquierda {
  max-width: 50vh;
  max-height: 50vh;
}
.imagen-inferior-derecha {
  max-width: 50vh;
  max-height: 50vh;
}
h3 {
  margin-bottom: 2%;
}
h2 {
  margin-bottom: 1%;
}
.container-imagenes {
  display: flexbox;
  justify-content: center;
  width: 105vh;
  background-color: rgb(0, 0, 0);
}

.imagen-superior {
  display: flex;
  justify-content: center;
  margin-bottom: 0.5%;
  max-width: 100vh;
  max-height: 30vh;
}

.imagen-superior-container {
  width: 50vh;
  margin-left: 25vh;
  background-color: black;
}
.imagenes-izquierda {
  background: rgb(0, 0, 0);
  max-height: 100vh;
}

.imagen-derecha {
  display: inline;
}

.footer-marketing {
  display: flex;
  justify-content: space-evenly;
  margin: 2%;
}

.footer-information {
  display: flex;
}

.informacion-derecha {
  margin-top: 4%;
  max-width: 50vh;
}
.descripcion-producto {
  font-family: rubik;
}
.catalogo-item-title {
  font-size: 5vh;
  padding-top: 2%;
  padding-bottom: 2%;
}

.precios {
  margin-top: 5%;
  margin-bottom: 5%;
}

.bloob-logo-abajo {
  height: 100%;
}

.logo-de-Bloob-izquierda {
  max-width: 7%;
  padding-right: 0px;
}

.footer-information {
  padding-top: 1%;
  max-height: 100px;
}

.footer-marketing {
  border-style: solid;
  border-color: white;
  padding: 1%;
}

.texto-footer-information {
  border-left: solid;
  padding-left: 2%;
}

.verde {
  color: green;
}

.solicita-disenio {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  height: 5vh;
  margin-bottom: 3%;
  transition-duration: 0.45s;
  border-radius: 50px;
  padding: 20px;
  padding-top: 10px;
}

.solicita-tu-disenio-button {
  margin-bottom: 5%;
  align-items: center;
}

.envio-y-bandera {
  margin-top: 3%;
  margin-bottom: 5%;
}

.camionsito {
  display: flex;
  justify-content: center;
  margin-bottom: 1vh;
}
.garantia-icon {
  display: flex;
  justify-content: center;
  margin-bottom: 1vh;
}

.tela-icon {
  display: flex;
  justify-content: center;
  margin-bottom: 1vh;
}
h2 {
  font-family: unica;
}
h3 {
  font-family: rubik;
}

h1 {
  font-family: rubik;
}

.precio-text-prod-ind {
  font-size: 1vw;
}
.boton-text-prod-ind {
  display: flex;
  align-items: center;
  margin-top: 3%;
  font-size: 1vw;
  color: white;
  font-family: rubik;
  border: none;

  height: 5vh;
  font-weight: 500;
}
.boton-text-prod-ind-solicita {
  align-items: center;
  font-size: 1vw;
  color: rgb(0, 0, 0);
  font-family: rubik;
  border: none;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
}

.senia-texto {
  font-size: 20px;
}
.verde {
  font-size: 21px;
  color: #25d366;
}
.demora-texto {
  font-size: 20px;
}
.promociones-texto {
  font-size: 15px;
}
.catalogo-item-title {
  margin-top: 5%;
  font-size: 30px;
}
.titulo-principal-de-producto {
  font-size: 55px;
}
.subtitulo-prod-ind {
  font-size: 20px;
}
.descripcion-producto {
  font-size: 20px;
  font-weight: 100;
}
.bottom-text-box {
  font-size: 1.3vw;
}
.solicita-disenio:hover {
  background-color: #000000; 
  color: white;
  border-color: white;
}

.solicita-disenio:hover .boton-text-prod-ind-solicita {
  color: white;
}

.whatsapp-loguito {
  text-align: end;
}

/*
Imagen superior logic to open and close (zoom)
*/

.imagen-superior-container {
  cursor: zoom-in;
}
.imagen-superior-container.open {
  height: 50vh;
  cursor: zoom-out;
}
.imagen-superior-container .shade {
  margin-top: 5vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  opacity: 0;
  background: rgba(0, 0, 0, 0.9);
}
.imagen-superior-container.open .shade {
  pointer-events: auto;
  opacity: 1;
}
.imagen-superior-container img {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.imagen-superior-container.open img {
  position: fixed;
  margin-top: 10vh;
  height: auto;
  zoom: 150%;
  width: auto;
  margin: auto;
  max-height: 50vh;
}

/*
Imagen izquierda logic to open and close (zoom)
*/
.imagen-izquierda {
  cursor: zoom-in;
}
.imagen-izquierda.open {
  height: 50vh;
  cursor: zoom-out;
  min-width: 25vw;
}
.imagen-izquierda .shade {
  margin-top: 5vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  opacity: 0;
  background: rgba(0, 0, 0, 0.9);
}
.imagen-izquierda.open .shade {
  pointer-events: auto;
  opacity: 1;
}
.imagen-izquierda img {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.imagen-izquierda.open img {
  position: fixed;

  margin-top: 10vh;
  height: auto;
  zoom: 150%;
  width: auto;
  margin: auto;
  max-height: 50vh;
}

/*
Imagen derecha logic to open and close (zoom)
*/

.imagen-derecha {
  cursor: zoom-in;
}
.imagen-derecha.open {
  height: 50vh;
  cursor: zoom-out;
  min-width: 25vw;
}
.imagen-derecha .shade {
  margin-top: 5vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  opacity: 0;
  background: rgba(0, 0, 0, 0.9);
}
.imagen-derecha.open .shade {
  pointer-events: auto;
  opacity: 1;
}
.imagen-derecha img {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.imagen-derecha.open img {
  position: fixed;
  margin-top: 10vh;
  height: auto;
  zoom: 150%;
  width: auto;
  margin: auto;
  max-height: 50vh;
}

/*
*/

@media only screen and (max-width: 1024px) {

  /*
  */
  .imagen-superior-container.open img{
    position: fixed;
    margin-top: 10vh;
    height: auto;
    zoom: 110%;
    width: auto;
    margin: auto;
    max-height: 50vh;
    max-width: 100vw;
  }
  /*
  Imagen derecha menos zoom para responsive
  */
  .imagen-derecha.open img {
    position: fixed;
    margin-top: 10vh;
    height: auto;
    zoom: 110%;
    width: auto;
    margin: auto;
    max-height: 50vh;
    max-width: 100vw;
  }
  /*
  Imagen izquierda menos zoom para responsive
  */
  .imagen-izquierda.open img {
    position: fixed;
    margin-top: 10vh;
    height: auto;
    zoom: 110%;
    width: 100vw;
    margin: auto;
    margin-right: 0px;
    margin-left: 0px;
    max-height: 50vh;
  }


  /*
  */

  .todo-en-verproducto {
    padding-top: 4%;
    overflow-x: hidden;
  }

  .division-izquierda-derecha {
    display: grid;
    width: 100vw;
    place-items: center;
  }
  .imagenes-inferiores {
    display: grid;
    grid-column: 1;
    max-width: 100vw;
  }

  .titulo-camiseta-aca {
    font-family: unica;
    font-size: 130%;
    margin-bottom: 1%;
  }
  .imagen-superior-container {
    margin: 0px;
  }

  .imagen-inferior-izquierda {
    display: grid;
  }
  .imagen-inferior-derecha {
    display: grid;
    align-self: center;
    text-align: center;
    grid-column: 1;
    margin: 0;
    height: 40vh;
  }
  h3 {
    margin-bottom: 2%;
  }
  h2 {
    margin-bottom: 1%;
  }
  .container-imagenes {
    display: grid;
    justify-content: center;
    width: 100vw;
    background-color: rgb(0, 0, 0);
  }

  .imagen-superior {
    display: flex;
    justify-content: center;
    margin-bottom: 0.5%;
  }
  .imagen-superior-container {
    width: 50vh;
  }
  .imagenes-izquierda {
    max-height: 100vh;
  }

  .imagen-derecha {
    display: grid;
    margin-left: 0px;
    justify-content: center;
  }

  .footer-marketing {
    display: flex;
    justify-content: space-evenly;
    width: 90vw;
    text-align: center;
    margin: 1vw;
  }

  .footer-information {
    display: flex;
  }

  .informacion-derecha {
    margin-top: 50vh;
    display: grid;
    justify-content: center;
    width: 100vw;
    text-align: center;
  }
  .descripcion-producto {
    font-family: rubik;
  }
  .catalogo-item-title {
    font-size: 5vh;
    padding-top: 2%;
    padding-bottom: 2%;
  }

  .precios {
    display: grid;
    justify-content: center;
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .bloob-logo-abajo {
    height: 100%;
  }

  .logo-de-Bloob-izquierda {
    max-width: 7%;
    padding-right: 0px;
  }

  .footer-information {
    padding-top: 1%;
    max-height: 100px;
  }

  .texto-footer-information {
    border-left: solid;
    padding-left: 2%;
  }

  .verde {
    color: green;
  }

  button {
    background-color: #25d366;
  }

  .solicita-disenio {
    border-radius: 50px;
    height: 50px;
  }

  .solicita-tu-disenio-button {
    margin-top: 3%;
    margin-bottom: 5%;
  }

  .envio-y-bandera {
    margin-top: 3%;
    margin-bottom: 5%;
  }

  .camionsito {
    display: flex;
    justify-content: center;
    margin-bottom: 1vh;
  }
  .garantia-icon {
    display: flex;
    justify-content: center;
    margin-bottom: 1vh;
  }

  .tela-icon {
    display: flex;
    justify-content: center;
    margin-bottom: 1vh;
  }

  .precio-text-prod-ind {
    font-size: 100%;
  }
  .boton-text-prod-ind {
    font-size: 15px;
  }
  .senia-texto {
    font-size: 100%;
  }
  .verde {
    font-size: 90%;
  }
  .demora-texto {
    font-size: 90%;
  }
  .promociones-texto {
    font-size: 80%;
  }
  .catalogo-item-title {
    margin-top: 3%;
    font-size: 180%;
    margin-bottom: 3%;
  }
  .descripcion-producto {
    font-size: 110%;
    padding-left: 10%;
    padding-right: 10%;
  }
  .bottom-text-box {
    font-size: 80%;
    padding: 10%;
  }
  .boton-text-prod-ind-solicita {
    align-items: center;
    font-size: 10px;
    color: rgb(0, 0, 0);
    font-family: rubik;
    border: none;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    padding-bottom: 3vh;
  }
}

@font-face {
  font-family: unica;
  src: url(../../public/UnicaOne-Regular.ttf);
}
@font-face {
  font-family: rubik;
  src: url(../../public/Rubik-VariableFont_wght.ttf);
}
