.buttonDiv{
    display: flex;
    justify-content: center;
    border: none;
    margin-top: 2%;
    margin-bottom: 5%;
}

button:hover{
    cursor: pointer
}

.buttonProductos {
    font-family: rubik;
    color: white;
    background-color: black;
    transition: 0.5s;
    width: 100%;
    font-size: 250%;
    padding-top: 5%;
    padding-bottom: 5%;
    font-weight: 500;
    border: none;
}

.buttonProductos:hover {
    font-size: 300%;
}
@font-face {
    font-family: rubik;
    src: url(../../public/Rubik-VariableFont_wght.ttf);
  }
  @font-face {
    font-family: unica;
    src: url(../../public/UnicaOne-Regular.ttf);
  }

  @media screen and (max-width: 992px) {
    .buttonProductos {
        font-size: 25px;
        font-weight: 400;
    }
}