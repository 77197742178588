.titulo-y-boton {
  display: flex;
  margin-top: 3vh;
}

.titulo-faq-question {
  transition: all 0.2s ease;
}

.respuesta-pregunta {
  padding: 0%;
  overflow: hidden;
  max-height: 0px;
  transition: max-height 0.2s;

}

.respuesta-pregunta.open {
  max-height: 500px;
  color: rgb(255, 255, 255);
  transition: max-height 1s;

}
