:root {
  color-scheme: light only;
}

* {
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  color-scheme: only light;
  
}

@font-face {
  font-family: antonio;
  src: url(../public/Antonio-VariableFont_wght.ttf);
}

@font-face {
  font-family: unbounded;
  src: url(../public/Unbounded-VariableFont_wght.ttf);
}


.div-main{
  display: flexbox;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vw;
  max-width: 100vw;
}

.parent-footer{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

html {
  width: 100%;
  height: 100%;
  scroll-behavior: none;

}

body {
  background-color: black;
  overflow-x: hidden;
  display: flex;

}

.multiple-container {
  display: flex;
  justify-content: center;
}
.multiple {
  display: flex;
  justify-content: space-evenly;
  max-height: 85vh;
  margin-bottom:10vw;
  width: 100vw;
  max-width: 100vw;
  justify-items: center;
  
}

.fade-right-blur-transition{
  display: flex;
  align-items: center;
  flex-direction: row;
}

@media only screen and (max-width: 1024px) {
  .main{
    min-width: fit-content;
  }
  .body{
    max-width: 100%;
    overflow-x: hidden;

  }
  .multiple-container{

    display: grid;
    max-height: 300vh;
    text-align: center;
  }
  .multiple{
    display: grid;
    max-height: 150vh;
    text-align: center;
    justify-content: center;
  }
  .colaboraciones-css{
    display: grid;
    max-height: 100vh;
  }
  *{
    max-width: 100vw;
  }

}

