.cart{
    padding-top: 4%;
}

.titulo-carrito{
    font-family: unica;
    display: flex;
    justify-content: center;
    font-size: 400%;
    margin-bottom: 3%;
    margin-top: 5vh;
}

.div-de-carrito-logo{
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
}

.checkout{
    display: grid;
    text-align: center;
    justify-content: center;
    margin-bottom: 5%;
}

.button-checkout-container{
    width: 100%;
}

.boton-checkout{
    width: 100%;
}
