.cartItem {
  display: flex;
  width: 80vw;
  justify-content: space-evenly;
  border: solid;
  border-color: #fdffec;
  border-width: 1px;
  border-radius: 60px;
  height: fit-content;
  margin-bottom: 4%;
}
.cart-items{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.imagen-carrito-producto-container .imagen-carrito-producto {
  width: 10vw;
}

.informacion-pedido {
  justify-content: center;
  align-self: center;
}

.count-handler {
  align-self: center;
}

.boton-carrito-cantidad {
  color: black;
  background-color: white;
  height: auto;
  width: 30px;
  height: 32px;
  border: none;
  font-weight: 800;
}

.input-carrito-cantidad {
  width: 30px;
  height: 30px;
  text-align: center;
  border: none;
  font-weight: 800;
}

.div-titulo-izq-carrito{
    display: flex;
    width: fit-content;
    text-align: right;
}

.left-side-carrito{
    display: flex;
    text-align-last: left;
    width: 65%;
}