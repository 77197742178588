.column {
  display: flexbox;
  justify-content: center;
  float: left;
  padding: 10px;
}

.imagen-producto-ind {
  height: 100%;
  width: 100%;
}

.titulo-producto-ind {
  font-family: rubik;
  justify-content: center;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 200;
  margin-top: 1vh;
  color: white;
  text-decoration:none;

}

.container-img-btn {
  position: relative;
  width: 105%;
  transition: 3s;
}
.container-img-btn:before {
  transition: 1s;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0);
}
.container-img-btn:hover::before {
  background-color: rgba(0, 0, 0, 0.5);
}
.container-img-btn img {
  display: block;
}
a{
  text-decoration: none;
}
.container-img-btn button {
  transition: 1s;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  opacity: 0;
}
.container-img-btn:hover button {
  opacity: 1;
}

.button-ver-producto {
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  width: 50%;
  height: 20%;
  font-size: 150%;
  border: none;
  font-weight: bolder;
}

@font-face {
  font-family: rubik;
  src: url(../../public/Rubik-VariableFont_wght.ttf);
}

@font-face {
  font-family: unica;
  src: url(../../public/UnicaOne-Regular.ttf);
}


@media only screen and (max-width: 700px) {

  .container-img-btn:hover button {
    opacity: 0;
  }
  .container-img-btn:hover::before {
    background-color: rgba(0, 0, 0, 0);
  }
.button-ver-producto {
  background-color: black;
  color: white;
  width: 50%;
  height: 20%;
  font-size: 5vw;
  border: none;
  font-weight: bolder;
}
.titulo-producto-ind{
  font-size: 20px;
  font-weight: lighter;
}
}