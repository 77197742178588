.bannerImageDiv {
  height: 40vh;
  width: 100%;
  max-height: 40vh;
}

.bannerImagePic {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 1024px) {
  .bannerImageDiv {
    height: 25vh;
  }
}

@media only screen and (max-width: 720px) {
  .bannerImageDiv {
    height: 20vh;
  }
}
