.container {
    width: 100%;
    height: 100%;
}

.dropdown {
    width: 400px;
    margin: 0 auto;
    user-select: none;
    position: relative;
}

.dropdown .dropdown-btn{
    padding: 10px;
    background: #fff;
    font-weight: bold;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dropdown .dropdown-content {
    position: absolute;
    top: 42%;
    padding: 15px;
    background: #fff;
    font-weight: 500;
    color: #333;
    width: 82%;
}

.dropdown .dropdown-content .dropdown-item{
    padding: 10px;
    cursor: pointer;
    transition: all 0.2s;
}

.dropdown .dropdown-content .dropdown-item:hover{
    background: #f4f4f4;
}