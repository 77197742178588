.fraseDireccion{
    display: flex;
    justify-content: center;
    padding-left: 7%;
    color: white;
    margin-top: 60px;
  }

  .direccionP{
    font-size: 70px;
  }