.container-calculador {
  background-color: black;
  display: flexbox;
  padding-top: 5%;
  justify-content: center;
}
.presupuestos-titulo {
  display: flexbox;
  text-align: center;
}
.h1-texto {
  font-family: unica;
  font-size: 450%;
}
.final-section {
  display: flex-box;
  align-self: right;
}
.custom-day-container {
  background-color: rgb(194, 149, 149);
  max-width: fit-content;
  display: inline-flex;
  border-radius: 1px;
  border: solid;
  border-color: white;
  color: white;
}

.MuiPickersCalendarHeader-label {
  color: rgb(252, 252, 252);
}
.cantidad-cajita {
  height: 3.8vh;
}
.mini-titulo-producto {
  min-width: 15vh;
  text-align: center;
}
.info-superior {
  display: flexbox;
}
.row-containers {
  display: flexbox;
  justify-content: center;
  width: 100%;
}
.row{
  
  gap: 1%;
  
}
.info-and-row-containers{
  width: 100%;
}

.dropdown-btn{
  height: 2vh;
}

.info-superior {
  display: flex;
  justify-content: space-between;
  align-items: top;
  max-width: 80vw;
}
.carrito-distancia{
  margin-left: 0vw;
}
.productos-distancia{
}
.extras-distancia{
}
.telas-distancia{
}
.unidades-distancia{
}
.efectivo-distancia{
}
.transferencia-distancia{
}
.final-section{
  display: inline-flex;
}

.der-final-section{
  display: inline-flex;

}

.size-text{
  font-size: 150%;
}

.izq-final-section{
  margin-top: 5vh;
  margin-right: 5vh;
}

.transferencia-final-section{
  margin-right: 3vh;
}

.efectivo-final-section{
  margin-left: 3vh;
}

.comencemos-button{
  display: flex;
  justify-content: center;
  
  width: 90%;
  margin-top: 2vh;
  height: 4vh;
  font-size: 250%;
  font-style: rubik;
  margin: 0;
}
.precios{
  display: inline-flex;
}

.same-line{
  display: inline-flex;
}
.boton-calcular{
  width: 5vh;
  height: 5vh;
}
.button-calculator-personas{
  margin-left: 1vh;
}
.input-cantidad-jugadores{
  margin-left: 5vh;
}
.button-div-comencemos{
  margin-top: 3%;
  display: flex;
  justify-content: center;
}


@media screen and (max-width: 1024px) {
  .h1-texto{
    display: grid;
    text-align: center;
    justify-content: center;
    margin-top: 30%;
    max-width: 90vw;
    font-size: 200%;
  }
  .presupuestos-titulo{
    display: grid;
    text-align: center;
    justify-content: center;
  }
  .info-superior{
    visibility: hidden;
    max-height: 1vh;
    display: grid;
  }
  .row{
    display: grid;
    text-align: center;
    margin-bottom: 5%;
    width: 100%;
    margin-left: 0px;
  }
  .row-containers{
    max-width: 100vw;
  }
  .dropdown{
    max-width: 90vw;
  }

  .boton-container{
    display: grid;
    justify-content: center;
  }
  .boton-calcular{
    width: 30vw;
  }
  .final-section{
    display: grid;
    justify-content: center;
    text-align: center;
    max-width: 100vw;
    margin-left: 3%;
  }
  .button-div-comencemos{
    display: grid;
    justify-content: center;
  }
  .senia-para-comenzar{
    visibility:hidden;
  }
  .der-final-section{
    max-width: 90vw;
  }
  .efectivo-final-section{
    margin: 0;
  }
  .izq-final-section{
    max-width: 90vw;
  }
  h1{
    font-size: 100%;
  }
  .same-line{
    display: grid;
    text-align: center;
    align-self: center;
    justify-content: center;
  }
  .input-cantidad-jugadores{
    justify-content: center;
    text-align: center;
    margin: 0px;
  }
  .cantidad-cajita{
    margin-top: 1%;
  }
  .button-calculator-personas{
    margin: 0px;
  }
  .izq-final-section{
    margin:0px;
  }
  
}

@font-face {
  font-family: rubik;
  src: url(../../public/Rubik-VariableFont_wght.ttf);
}

@font-face {
  font-family: unica;
  src: url(../../public/UnicaOne-Regular.ttf);
}
