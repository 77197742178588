.container-video{
  display: flex;
  justify-content: center;
  width: 100%;
}
.video{
  display: flex;
  justify-content: center;
  width: 100%;
  }

  .back-video{
    width: 100%;
    height: 100%;
  }