.container-de-preguntas {
  padding-top: 4%;
  color: white;
}

.btn-preguntas {
  background: transparent;
  border-color: transparent;
  width: 55px;
  height: 55px;
  background: rgb(252, 63, 233);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  color: rgb(0, 0, 0);
  cursor: pointer;
  margin-left: 1rem;
  align-self: center;
  transition: 4s;
  min-width: 55px;
  min-height: 55px;
}

.titulo-de-page {
  font-family: unica;
  display: flex;
  justify-content: center;
  font-size: 400%;
  margin-bottom: 3%;
  margin-top: 5%;
  margin-left: 2%;
}
.titulo-faq-ques {
  cursor: pointer;
  user-select: none;
  font-size: 1.9vw;
}
.respuesta-pregunta {
  max-width: 90vw;
  font-family: rubik;
}
.preguntas-frec-titulo {
  margin-top: 5vh;
}
.question {
  display: flexbox;
  justify-content: center;
  width: 97%;
  font-size: 350%;
  margin: 2%;
  font-family: rubik;
}

.container-de-preguntas {
  display: flexbox;
  justify-content: center;
  width: 100%;
}

.info {
  display: flexbox;
  padding-right: 10%;
  padding-left: 10%;
  transition: 2s;
}

.info:hover .btn-preguntas {
  background: rgb(0, 157, 255);
}

@font-face {
  font-family: rubik;
  src: url(../../public/Rubik-VariableFont_wght.ttf);
}

@font-face {
  font-family: unica;
  src: url(../../public/UnicaOne-Regular.ttf);
}

@media only screen and (max-width: 1024px) {
  .preguntas-frec-titulo {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 9vw;
    margin-top: 20%;
  }
  .respuesta-pregunta {
    width: fit-content;
    padding-left: 0px;
    padding-right: 0px;
  }

  .titulo-y-boton {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3vh;
    max-width: 90vw;
  }
  .titulo-faq-ques {
    font-size: 30px;
    font-weight: 500;
  }
}
