.quienes-somos-container {
  padding-top: 4%;
  width: 98%;
  display: flexbox;
  justify-content: center;
}

.quienes-somos-title {
  font-family: unica;
  display: flex;
  justify-content: center;
  font-size: 400%;
  margin-bottom: 3%;
  margin-top: 5vh;
  margin-left: 2%;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(255, 255, 255);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(34, 34, 34);
}

.quienes-somos-description {
  font-weight: 200;
  margin-bottom: 3%;
  margin-top: 3%;
  font-family: rubik;
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: justify;
  padding-left: 10%;
  padding-right: 10%;
  font-size: 1.8vw;
  max-width: 90vw;
}

.imagen-container-qs {
  display: grid;
  justify-content: center;
  align-self: center;
  margin-bottom: 5%;
  justify-items: center;
}

.imagen-faq {
  width: 90%;
}

@font-face {
  font-family: rubik;
  src: url(../../public/Rubik-VariableFont_wght.ttf);
}

@font-face {
  font-family: unica;
  src: url(../../public/UnicaOne-Regular.ttf);
}

@media screen and (max-width: 1024px) {
  .quienes-somos-title {
    margin-top: 22%;
    text-align: center;
    font-size: 400%;
  }
  .quienes-somos-description {
    font-size: 150%;
  }
}
