.container {
    width: 100%;
    height: 100%;
}

.dropdown {
    width: 100px;
    margin: 0 auto;
    user-select: none;
    position: relative;
}

.dropdown .dropdown-btn{
    background: #fff;
    box-shadow: 3px 3px 10px 6px rgba(0,0,0,0.86);
    font-weight: bold;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    
}


.dropdown .dropdown-content {
    position: absolute;
    background: #fff;
    font-weight: 500;
    color: #333;
    width: 100%;
    z-index: 100;
    margin-top: 3%;

}

.dropdown .dropdown-content .dropdown-item{
    cursor: pointer;
    transition: all 0.2s;
}

.dropdown .dropdown-content .dropdown-item:hover{
    background: #f4f4f4;
}